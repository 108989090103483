import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const shouldShowPagination = (showPagination, resultCount, pageSize) => {
  if (showPagination !== undefined ? showPagination : true) {
    return resultCount > pageSize;
  }
  return false;
};

const BaseTable = ({
  tableRef,
  data,
  loading,
  pages,
  resultCount,
  filtered,
  filterable,
  filteredArgs,
  columns,
  defaultFilterMethod,
  defaultSorted,
  defaultSortDesc,
  onFetchData,
  showPagination,
  resizable,
}) => {
  const pageSize = 20;

  return (
    <ReactTable
      manual
      className="-highlight"
      loadingText="Cargando..."
      pageSize={pageSize}
      defaultPageSize={10}
      minRows={1}
      pageText="Página"
      previousText="Anterior"
      nextText="Siguiente"
      noDataText="Sin resultados"
      rowsText="Filas"
      multiSort={false}
      showPageSizeOptions={false}
      showPagination={shouldShowPagination(showPagination, resultCount, pageSize)}
      ref={tableRef}
      resizable={resizable}
      data={data}
      loading={loading}
      pages={pages}
      filterable={filterable}
      filtered={filtered}
      filteredArgs={filteredArgs}
      columns={columns}
      defaultFilterMethod={defaultFilterMethod}
      defaultSorted={defaultSorted}
      defaultSortDesc={defaultSortDesc || false}
      onFetchData={onFetchData}
      getTheadProps={() => ({
        style: {
          background: '#EFEFEF',
          padding: '10px 0 0 10px',
        },
      })}
      getTheadThProps={() => ({
        style: { border: 'none' },
      })}
      getTrGroupProps={() => ({
        style: { border: 'none' },
      })}
      getTdProps={() => ({
        style: { border: 'none' },
      })}
      getTableProps={() => ({
        style: { overflowX: 'scroll' },
      })}
    />
  );
};

BaseTable.propTypes = [];

export default BaseTable;
