import {
  GET_FILE_LIST
} from '../constants/action-types';

const initialState = {
  list: [],
  listData: {
    data: [],
    pages: 0,
    count: undefined,
    next: undefined,
    previous: undefined,
  },
  searchedUser: undefined,
  showSuccessMessage: false
};

const executeGetUserListSuccess = (state, action) => {
  return {
    ...state,
    listData: {
      data: action.payload.results,
      pages: Math.ceil(action.payload.count / 20),
      count: action.payload.count,
      next: action.payload.next,
      previous: action.payload.previous,
    },
  }
};

function files(state = initialState, action) {
  switch (action.type) {
    case GET_FILE_LIST:
      return executeGetUserListSuccess(state, action);
    default:
      return state;
  }
}

export default files;
