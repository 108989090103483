export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const TOKEN_ERROR = "TOKEN_ERROR";

export const GET_FILE_LIST = "GET_FILE_LIST";
export const GET_USER_INFO = "GET_USER_INFO";
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const PUT_DATA_SUCCESS = 'PUT_DATA_SUCCESS';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const GET_USER_LIST = "GET_USER_LIST";
export const SEARCH_USER = "SEARCH_USER";
export const GET_LIST_CLIENT = "GET_LIST_CLIENT";
export const GET_LIST_CLIENT_ORGANIZATION = "GET_LIST_CLIENT_ORGANIZATION";
