import React, { Component } from 'react';
import PropTypes from 'prop-types';
import matchSorter from "match-sorter";
import { Button } from 'reactstrap';
import { fdate } from '../helpers/helpers'
import { BASE_URL_FILE, FRONT_DF_1 } from '../CONST';
import { cellOperation, headerOperation } from '../helpers/tables';
import BaseTable from './UI/BaseTable';


class DocumentListTable extends Component {
  constructor(props) {
    super(props);

    this.fileDownload = this.fileDownload.bind(this);
  }

  fileDownload(url, filename) {
    const { token } = this.props;
    const headersJWT = token;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${BASE_URL_FILE}${url}`, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', `JWT ${headersJWT}`);
    xhr.onload = function onload() {
        if (this.status === 200) {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([this.response]));;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 1000);
        }
    };
    xhr.send()
  }

  render() {
    const { count, data, loading, onFetchData, pages } = this.props;
    const columns = [
      {
        Header: headerOperation('ID Documento'),
        id: 'id',
        accessor: (i) => cellOperation(i.id, 'center'),
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["_original.id"] }),
        filterAll: true,
        width: 120,
      },
      {
        Header: headerOperation('Fecha de subida'),
        id: 'created_at',
        accessor: (i) => cellOperation(fdate(i.created_at, FRONT_DF_1), 'center'),
        width: 250,
      },
      {
        Header: headerOperation('Sistema Fuente'),
        id: 'type__source_system__description__icontains',
        accessor: (i) => cellOperation(i.system),
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["_original.system"] }),
        filterAll: true,
        width: 250,
      },
      {
        Header: headerOperation('Tipo'),
        id: 'type__description__icontains',
        accessor: (i) => cellOperation(i.type),
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["_original.type"] }),
        filterAll: true,
        width: 250,
      },
      {
        Header: headerOperation('URL'),
        id: 'original_name__icontains',
        filterable: true,
        accessor: (i) => cellOperation(
          <Button
            className="table-link"
            onClick={() => {this.fileDownload(i.file, i.original_name)}}
          >
            {i.original_name}
          </Button>
        ),
        filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["_original.file"] }),
        filterAll: true,
        minWidth: 250,
      },
    ];

    return(
      <div style={{ marginTop: '30px' }}>
        <BaseTable
          filterable
          resizable={false}
          loading={loading}
          data={data}
          columns={columns}
          showPagination
          resultCount={count}
          pages={pages}
          onFetchData={onFetchData}
        />
      </div>
    );
  }
}

DocumentListTable.propTypes = {
  count: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  onFetchData: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

DocumentListTable.defaultProps = {
  count: undefined,
};

export default DocumentListTable;
