import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ModalFooter, ModalHeader } from 'reactstrap';
import BaseModal from './BaseModal';
import { alertModal } from '../../redux-store/actions';
import auth from '../../helpers/auth';

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => dispatch(alertModal(false)),
  }
}

class MissingTokenModal extends Component {
  constructor(props) {
    super(props);
    this.handleModal = this.handleModal.bind(this);
  }

  handleModal() {
    const { hideModal } = this.props;
    hideModal();
    auth.authenticate();
  }

  render() {
    const { tokenWarning } = this.props;
    return (
      <BaseModal
        handleModal={this.handleModal}
        modalOpen={tokenWarning}
        type="token"
      >
        <ModalHeader toggle={this.handleModal} />
        <div className="body">
          <div className="title-text font-weight-bold">
            Su sesión ha expirado
          </div>
          <div className="body-text">
            Por Favor, vuelva a iniciar sesión con sus credenciales
          </div>
        </div>
        <ModalFooter className="text-right">
          <Button className="token-accept" onClick={this.handleModal}>
            Aceptar
          </Button>
        </ModalFooter>
      </BaseModal>
    );
  }
}

const mapStateToProps = state => ({ tokenWarning: state.login.tokenWarning });

MissingTokenModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  tokenWarning: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MissingTokenModal);

