import {
  SEARCH_USER,
  GET_USER_LIST,
  POST_DATA_SUCCESS,
  PUT_DATA_SUCCESS,
  DELETE_DATA_SUCCESS,
} from '../constants/action-types';

const initialState = {
  list: null,
  searchedUser: undefined,
  showSuccessMessage: false
};

const executeSearchUserSuccess = (state, action) => {
  return {
    ...state,
    searchedUser: action.payload.data
  }
};

const executeGetUserListSuccess = (state, action) => {
  return {
    ...state,
    list: action.payload
  }
};

const executePostDataSuccess = (state) => {
  return {
    ...state,
    showSuccessMessage: true,
  }
};

const executePutDataSuccess = (state) => {
  return {
    ...state,
    showSuccessMessage: true,
  }
};

const executeDeleteDataSuccess = (state) => {
  return {
    ...state,
    showSuccessMessage: true,
  }
};

function users(state = initialState, action) {
  switch (action.type) {
    case SEARCH_USER:
      return executeSearchUserSuccess(state, action);
    case GET_USER_LIST:
      return executeGetUserListSuccess(state, action);
    case POST_DATA_SUCCESS:
      return executePostDataSuccess(state, action);
    case PUT_DATA_SUCCESS:
      return executePutDataSuccess(state, action);
    case DELETE_DATA_SUCCESS:
      return executeDeleteDataSuccess(state, action);
    default:
      return state;
  }
}

export default users;
