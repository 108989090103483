import React from 'react';

const moment = require('moment');
require('moment/locale/es');

const currentDate = moment().format('dddd DD [de] MMMM [de] YYYY');

const TimeDate = () => {
  return (
    <div className="text-right date">
      {`${currentDate.charAt(0).toUpperCase()}${currentDate.slice(1)}`}
    </div>
  );
};

export default TimeDate;
