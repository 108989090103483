import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const composeEnhancers = composeWithDevTools({});
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk),
));

export default store;
