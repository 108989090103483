import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import users from './users';
import files from './files';
import login from './login';

const rootReducer = combineReducers({
  users,
  files,
  login,
  routing: routerReducer,
});

export default rootReducer;
