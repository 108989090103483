const BASE_URL = process.env.REACT_APP_API;
const BASE_URL_FILE = process.env.REACT_APP_URL;
const BASE_OAUTH = process.env.REACT_APP_OAUTH;
const CLIENT_ID = process.env.REACT_APP_CLIENT;
const LOCAL_URL = process.env.REACT_APP_LOCAL;
const USERS_URL = process.env.REACT_APP_USERS;
const POLICY_URL = process.env.REACT_APP_POLICY;
const ACCOUNTANT_URL = process.env.REACT_APP_ACCOUNTANT;
const REASEGURO_URL = process.env.REACT_APP_REASEGURO;
const RISK_URL = process.env.REACT_APP_RISK;
const WORKFLOW_URL = process.env.REACT_APP_WORKFLOW;
const ANALYST_URL = process.env.REACT_APP_ANALYST;
const ORSAN_LOGO_URL = "https://orsan-static.s3.amazonaws.com/public/logotipo_orsan_seguros.png";

const API = {
  login: 'authorize',
  endSession: 'end-session',
  userinfo: 'userinfo',
  files: 'v1/files',
};

const FRONT_DF_1 = 'DD-MM-YYYY';

export {
  ACCOUNTANT_URL,
  API,
  BASE_URL,
  BASE_URL_FILE,
  BASE_OAUTH,
  CLIENT_ID,
  LOCAL_URL,
  POLICY_URL,
  RISK_URL,
  USERS_URL,
  WORKFLOW_URL,
  ANALYST_URL,
  REASEGURO_URL,
  FRONT_DF_1,
  ORSAN_LOGO_URL
};
