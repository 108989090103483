import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageContainer from './PageContainer';
import DocumentListTable from './DocumentListTable';

import { getData } from '../redux-store/actions/index';
import { GET_FILE_LIST } from '../redux-store/constants/action-types';
import { API, BASE_URL } from '../CONST';

const api = API.files;

function convertDateFormat(dateString) {
  const parts = dateString.split('-');
  if (parts.length !== 3) return null;
  const [day, month, year] = parts;
  return `${year}-${month}-${day}`;
}
function isValidDate(dateString) {
  const regex = /^\d{2}-\d{2}-\d{4}$/;
  if (!dateString.match(regex)) return false;

  const convertedDate = convertDateFormat(dateString);
  if (!convertedDate) return false;

  const date = new Date(convertedDate);
  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) return false;

  return true;
}
function mapDispatchToProps(dispatch) {
  return {
    getDocumentList: files => dispatch(getData(files)),
  };
}

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const { getDocumentList } = this.props;

    this.setState({ loading: true });

    let filters = `${BASE_URL}${api}/files/?page=${state.page + 1}&`;
    if (state.filtered.length) {
      state.filtered.forEach((node) => {
        if (node.value.length !== 0) {
          if (node.id === 'created_at' && isValidDate(node.value)) {
            const formattedDate = convertDateFormat(node.value);
            filters += `${node.id}__gte=${formattedDate}T00:00:00&${node.id}__lte=${formattedDate}T23:59:59&`;
          } else if (node.id !== 'created_at') {
            const value = (node.value !== 'external_id' && node.value !== 'id') || (node.value.length > 2);
            filters = value ? `${filters}${node.id}=${node.value}&` : filters;
          }
        }
      });
    }

    getDocumentList({
      url: filters,
      name: 'files',
      type: GET_FILE_LIST,
    });
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { listData, idToken } = this.props;

    return (
      <PageContainer
        breadcrumbs={[
          { id: 1, name: 'DOCUMENTOS' },
          { id: 2, name: 'LISTA DE DOCUMENTOS' }
        ]}
      >
        <DocumentListTable
          data={listData.data}
          count={listData.count}
          loading={loading}
          pages={listData.pages}
          onFetchData={this.fetchData}
          token={idToken}
        />
      </PageContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    listData: state.files.listData,
    idToken: state.login.id_token
  }
};

DocumentList.propTypes = {
  getDocumentList: PropTypes.func.isRequired,
  idToken: PropTypes.string.isRequired,
  listData: PropTypes.shape({
    count: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    pages: PropTypes.number,
  }).isRequired,
};

const DocumentListView = connect(mapStateToProps, mapDispatchToProps)(DocumentList);

export default DocumentListView;
