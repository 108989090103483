import {
  DELETE_DATA_SUCCESS,
  LOGIN_SUCCESS,
  TOKEN_ERROR,
} from '../constants/action-types';
import auth from '../../helpers/auth';

export function alertModal(payload) {
  return { type: TOKEN_ERROR, payload }
}

export function loginSuccess(payload) {
  return { type: LOGIN_SUCCESS, payload }
}

const getDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  }
};

export const getData = object => {
  return (dispatch) => {
    auth.fetch(object.url)
      .then((response)  => {
        dispatch(getDataSuccess(response, object));
      })
      .catch(() => {
        // TODO: handle error
      });
  };
};

const postDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  }
};

export const postData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'POST',
      body: JSON.stringify(object.config),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => dispatch(postDataSuccess(response, object)))
      .catch(() => {
        if (object.onFailedReq) {
          object.onFailedReq();
        }
        // TODO: handle error
      });
  };
};

const putDataSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const putData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'PUT',
      body: JSON.stringify(object.config.body),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => dispatch(putDataSuccess(response, object)))
  };
};

const uploadFileSuccess = (payload, props) => {
  return {
    type: props.type,
    payload,
  };
};

export const uploadFile = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'PUT',
      body: object.config.body,
      headers: {
        'Content-Disposition': `attachment; filename="${encodeURI(object.config.name)}"`
      }
    })
    .then(response => dispatch(uploadFileSuccess(response, object)))
  };
};

const deleteDataSuccess = response => {
  return {
    type: DELETE_DATA_SUCCESS,
    response,
  }
};

export const deleteData = object => {
  return (dispatch) => {
    auth.fetch(object.url, {
      method: 'DELETE',
      body: '',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(() => dispatch(deleteDataSuccess(object)))
      .catch(() => {
        // TODO: handle error
      });
  };
};
