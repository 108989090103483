import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';

import UserList from '../components/DocumentList';
import AppRedirect from '../components/shared/AppRedirect';

const LoggedInRoutes = ({user}) => {
  return(
    !user.has_access ? (
      <AppRedirect />
    ) : (
      <Switch>
        <Route exact path="/" component={UserList} />
        <Route exact path="/documents/list" component={UserList} />
      </Switch>
    )
  )
};

LoggedInRoutes.propTypes = {
  user: PropTypes.shape({
    has_access: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    userToken: state.login.userToken,
    user: state.login.user,
  };
};

export default withRouter(connect(mapStateToProps)(LoggedInRoutes));
