import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
} from 'reactstrap';
import {
  ACCOUNTANT_URL,
  USERS_URL,
  POLICY_URL,
  RISK_URL,
  WORKFLOW_URL,
  ANALYST_URL,
  REASEGURO_URL,
  ORSAN_LOGO_URL
} from '../CONST';
import { signout } from '../helpers/auth';

class BaseNavbar extends Component {
  constructor(props) {
    super(props);

    this.toggleDocuments = this.toggleDocuments.bind(this);
    this.state = {
      documentsOpen: false,
    };
  }

  handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  toggleDocuments() {
    const { documentsOpen } = this.state;
    this.setState({
      documentsOpen: !documentsOpen
    });
  }

  render() {
    const { user } = this.props;
    const { documentsOpen } = this.state;

    return (
      <nav
        className="navbar-content"
      >
        <div className="nav-logo-container">
          <Link to="/">
            <img src={ORSAN_LOGO_URL} className="nav-logo" alt="" />
          </Link>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
          <ul className="nav-links-group">
            <Dropdown nav className="nav-link-element" isOpen={documentsOpen} toggle={this.toggleDocuments}>
              <DropdownToggle nav caret className="navbar-header">
                DOCUMENTOS
              </DropdownToggle>
              <DropdownMenu>
                {user.apps.includes('users') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={USERS_URL}>
                      USUARIOS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('policies') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={POLICY_URL}>
                      PÓLIZA
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('accountant') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={ACCOUNTANT_URL}>
                      RESERVAS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('reaseguro') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={REASEGURO_URL}>
                      REASEGURO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('risk') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={RISK_URL}>
                      RIESGO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('analista') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={ANALYST_URL}>
                      RIESGO DE CRÉDITO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('workflow') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={WORKFLOW_URL}>
                      WORKFLOW
                    </a>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <li className="nav-link-element">
              <NavLink href="/documents/list">Lista de Documentos</NavLink>
            </li>
          </ul>

          <ul className="nav-links-group">
            <li className="nav-link-element nav-ite dropdown">
              <p className="nav-link">{user ? user.username : ''}</p>
            </li>
            <li className="nav-link-element nav-item dropdown">
              <Button className="nav-link" onClick={this.handleSignOut}>Cerrar Sesión</Button>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
  }
};

BaseNavbar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    has_access: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
    organization: PropTypes.number,
    sub: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(BaseNavbar));
