import decode from 'jwt-decode';
import { LOGIN_SUCCESS, GET_USER_INFO, TOKEN_ERROR } from '../constants/action-types';

const initialState = {
  userToken: undefined,
  id_token: undefined,
  user: { email: null, username: null, apps: [] },
  tokenWarning: false,
};

const executeTokenError = (state, action) => {
  return {
    ...state,
    tokenWarning: action.payload == null ? true : action.payload,
  }
};

function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('validToken', true);
      return Object.assign({}, state, {
        userToken: action.payload.userToken,
        id_token: action.payload.idToken,
        user: action.payload.idToken ? decode(action.payload.idToken) : undefined,
      });
    case GET_USER_INFO:
      return Object.assign({}, state, {
        user: action.payload,
      });
    case TOKEN_ERROR:
      return executeTokenError(state, action);
    default:
      return state;
  }

}

export default login;
